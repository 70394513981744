import React from 'react'
import PropTypes from 'prop-types'
import { get, filter } from 'lodash'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { TiMail, TiPrinter, TiSocialPinterest } from 'react-icons/ti'
import lang from '../../static/lang.json'
import App from '../components/App'
import { Header, HeaderHeadline, HeaderDivider } from '../components/Hero'
import Main from '../components/Main'
import BreadCrumbs from '../components/Breadcrumbs'
import Grid, { Half } from '../components/Grid'
import { generateSlug, capitalizeWord } from '../helpers/slug'
import Wrapper from '../components/Wrapper'
import Panel, { PanelContainer } from '../components/Panel'
import CardRecipe from '../components/CardRecipe'
import { HrefLangMeta, useLang } from '../components/Locale'
import { FaRegClock } from 'react-icons/fa'
import { extractHTML } from '../helpers/iterators.js'
import { HeadingLevel2 } from '../components/Heading'
import ListSeparator from '../components/ListSeparator'
import SEO from '../components/SEO'
import { ShareIcon } from '../components/Button'
import CanonicalLink from '../components/CanonicalLink'

const getId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)

  if (match && match[2].length !== 11) {
    throw new Error("Doesn't match")
  }

  return match[2]
}

const extractProductIDs = (arr) => {
  const { variant } = arr
  const variantIDS = []
  const productIDS = []
  const categoryIDS = []

  if (variant) {
    variant.forEach((obj) => {
      variantIDS.push(obj.contentful_id)
      if (!obj.product) return

      obj.product.forEach((product) => {
        productIDS.push(product.contentful_id)
        categoryIDS.push(get(product, 'category.contentful_id'))
      })
    })
  }

  return {
    variants: variantIDS,
    products: productIDS,
    categories: categoryIDS,
  }
}

const Category = ({ data, location }) => {
  const { recipe, translation, related } = data

  const t = useLang()
  const locale = recipe.node_locale.toLowerCase()
  const inboundIDS = extractProductIDs(recipe)

  const filteredRelated = related.nodes.filter((obj) => {
    const relatedIDs = extractProductIDs(obj)
    return (
      relatedIDs.variants.some((r) => inboundIDS.variants.includes(r)) ||
      relatedIDs.products.some((r) => inboundIDS.products.includes(r)) ||
      relatedIDs.categories.some((r) => inboundIDS.categories.includes(r))
    )
  })

  return (
    <App>
      <SEO {...recipe} />
      <CanonicalLink canonical={recipe.canonical} />
      {translation && <HrefLangMeta {...translation} sub="recipes" />}

      <Header>
        <Grid>
          <Half>
            <HeaderHeadline style={{ marginBottom: 0 }}>
              {recipe.title}
            </HeaderHeadline>
            <p>
              <FaRegClock aria-hidden /> {t('readyIn')} {recipe.time}
            </p>
            <HeaderDivider />
            <BreadCrumbs
              title={recipe.title}
              breadcrumbs={[
                {
                  href: `/${lang.recipes[locale]}`,
                  label: lang.recipes[locale],
                },
              ]}
            />

            <ShareIcon
              style={{ background: '#222' }}
              href={`mailto:?subject=${recipe.title}&body=Checkout this recipe: ${location.href}`}
              aria-label={t('toEmail')}
            >
              <TiMail aria-hidden />
            </ShareIcon>

            <ShareIcon
              style={{ background: '#e60023' }}
              href="https://www.pinterest.com/pin/create/button/"
              data-pin-do="buttonBookmark"
              data-pin-custom="true"
              aria-label={t('toPinterest')}
              role="button"
              tabIndex={0}
            >
              <TiSocialPinterest aria-hidden />
            </ShareIcon>

            {recipe.pdf && (
              <a
                href={recipe.pdf.file.url}
                target="_blank"
                aria-label={t('toPdf')}
                style={{
                  background: '#8c6b0f',
                  border: 0,
                  borderRadius: '50%',
                  cursor: 'pointer',
                  display: 'inline-block',
                  color: '#FFF',
                  margin: '1rem .25rem 0',
                  height: '2rem',
                  padding: '.25rem',
                  textAlign: 'center',
                  width: '2rem',
                }}
              >
                <TiPrinter
                  aria-hidden
                  style={{
                    height: '80%',
                    marginTop: '5%',
                    width: '80%',
                  }}
                />
              </a>
            )}
          </Half>
          {recipe.photo && (
            <Half>
              <Image
                fluid={recipe.photo.fluid}
                alt={recipe.photo.title}
                style={{
                  display: 'block',
                  margin: '1rem auto',
                  height: 275,
                  width: 400,
                }}
              />
            </Half>
          )}
        </Grid>
      </Header>

      <Wrapper style={{ paddingTop: '2rem' }}>
        <Main>
          <Panel>
            <HeadingLevel2 center>{lang.ingredients[locale]}</HeadingLevel2>
            <PanelContainer>
              <div
                className="list-dividers"
                {...extractHTML(recipe, 'ingredients')}
              />
              <div {...extractHTML(recipe, 'tip')} />
              <div {...extractHTML(recipe, 'fastTrack')} />
            </PanelContainer>
          </Panel>
          <Panel style={{ margin: '4rem 0' }}>
            <HeadingLevel2 center>
              {lang.cookingInstructions[locale]}
            </HeadingLevel2>
            {recipe.video && (
              <iframe
                title={recipe.title}
                width="600"
                height="450"
                style={{
                  margin: '0 auto 2rem',
                  display: 'block',
                }}
                frameBorder="0"
                allowFullScreen
                src={`//www.youtube.com/embed/${getId(recipe.video)}`}
              />
            )}
            <ListSeparator {...extractHTML(recipe, 'instructions')} />
          </Panel>
          {filteredRelated.length ? (
            <React.Fragment>
              <HeadingLevel2 center>{lang.related[locale]}</HeadingLevel2>
              <Grid style={{ justifyContent: 'center' }}>
                {filteredRelated.map((node) => (
                  <CardRecipe
                    key={node.contentful_id}
                    button={lang.recipesView[locale]}
                    url={generateSlug(
                      [locale, lang.recipes[locale], node.title].join('/'),
                    )}
                    {...node}
                  />
                ))}
              </Grid>
            </React.Fragment>
          ) : null}
        </Main>
      </Wrapper>
    </App>
  )
}

export default Category

export const query = graphql`
  query getRecipeById($contentful_id: String, $node_locale: String) {
    translation: contentfulRecipe(
      contentful_id: { eq: $contentful_id }
      node_locale: { ne: $node_locale }
    ) {
      title
      node_locale
      contentful_id
    }

    recipe: contentfulRecipe(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      canonical
      title
      time
      serves
      node_locale
      pdf {
        file {
          url
        }
      }
      ingredients {
        childContentfulRichText {
          html
        }
      }
      fastTrack {
        childContentfulRichText {
          html
        }
      }
      tips {
        childContentfulRichText {
          html
        }
      }
      video
      instructions {
        childContentfulRichText {
          html
        }
      }
      photo {
        title
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      variant {
        contentful_id
        product {
          contentful_id
          category {
            contentful_id
          }
        }
      }
    }

    related: allContentfulRecipe(
      filter: {
        contentful_id: { ne: $contentful_id }
        node_locale: { eq: $node_locale }
      }
    ) {
      nodes {
        title
        serves
        time
        contentful_id
        image: photo {
          title
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        variant {
          contentful_id
          product {
            contentful_id
            category {
              contentful_id
            }
          }
        }
      }
    }
  }
`
