import styled from 'styled-components';
import vars from '../lib/styled';

export default styled.div`
    ol,
    ul {
        counter-reset: section;
        list-style: none;
        margin: 0;
        padding: 0;
        
        li {
            background-color: ${vars.contrast};
            margin-bottom: 1rem;
            padding: 2rem 3rem 2rem 8rem;
            position: relative;

            @media (max-width: ${vars.breakpoint.tablet}){
                padding: 3vh 6vw;
            }

            &::before {
                background-color: ${vars.success};
                border-radius: 50%;
                box-sizing: border-box;
                color: ${vars.contrast};
                counter-increment: section;
                content: counter(section);
                height: 2rem;
                font-size: .65rem;
                left: 3rem;
                line-height: 2rem;
                position: absolute;
                text-align: center;
                width: 2rem;

                @media (max-width: ${vars.breakpoint.tablet}){
                    display: block;
                    left: auto;
                    margin: 0 0 1rem;
                    position: relative;
                    top: auto;
                }
            }
        }
    }
`;
